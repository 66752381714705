import { Button, TextField, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import { constColor } from '../Constant/Constants';
import { postApiWithOutAuth } from '../../ApiBackend/ApiCal';
import { useParams } from 'react-router-dom';

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: constColor.outlinedColor,
      borderWidth: 2,
    },
    '&:hover fieldset': {
      borderColor: 'teal',
    },
  },
  marginLeft: '1rem',
});
const Unsubscribe = () => {
  const { email } = useParams();
  const [newEmail, setNewEmail] = useState(email ? email : '');
  const [isSubmit, setIsSubmit] = useState(false);
  const handleInputChange = (value: string) => {
    setNewEmail(value);
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (newEmail.includes('@') && newEmail.includes('.')) {
      postApiWithOutAuth('/api/v1/auth/unsubscribe', { email: newEmail }, {});
      setIsSubmit(true);
    } else {
      alert('Please enter valid email');
    }
  };
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      {isSubmit ? (
        <>
          <Typography variant="h6" sx={{ marginLeft: '1rem' }}>
            Your email address ({email}) has been successfully unsubscribed.
          </Typography>
          <Typography variant="h6" sx={{ marginLeft: '1rem' }}>
            We're sorry to see you go. If you change your mind in the future,
            feel free to resubscribe at any time.
          </Typography>
          <Typography variant="h6" sx={{ marginLeft: '1rem' }}>
            Thank you for your previous engagement!
          </Typography>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <CssTextField
            id="email"
            label="Email"
            variant="outlined"
            color="warning"
            value={newEmail}
            onChange={(event: any) => handleInputChange(event.target.value)}
          />
          <Button
            type="submit"
            sx={{ marginLeft: '1rem', marginTop: '0.5rem' }}
            variant="contained"
          >
            Unsubscribe Email
          </Button>
        </form>
      )}
      <br />
      <br />
    </>
  );
};

export default Unsubscribe;
