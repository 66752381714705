import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { agentOfferSliderDataInitial } from "../../Components/Common/Constant/schema/emptySchemaData";
import { objectStrAnyType } from "../../Components/Common/Constant/InterfaceConst";
import { fetchAgentOrders } from "../actions/agentOrdersAction";


const agentOfferSliderSlicer = createSlice({
    name: 'agentOrders',
    initialState: agentOfferSliderDataInitial,
    reducers: {
        updateData: (state, action: PayloadAction<objectStrAnyType[]>) => {
            state.agentOfferSlideData = action.payload;
        }        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAgentOrders.fulfilled, (state, action:PayloadAction<objectStrAnyType[]> ) => {
            state.agentOfferSlideData = action.payload;
        })
    }
})

export const {updateData} = agentOfferSliderSlicer.actions;
export default agentOfferSliderSlicer.reducer;
