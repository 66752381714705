import axios, { AxiosError } from "axios";
import { loginInterface } from "../Common/Constant/InterfaceConst";
import { apiURL } from "../Common/Constant/Constants";
let token = "";
export const GetHotelApiCal = async (location: string) => {
  try {
    if (!token || token === "" || token === undefined) {
      token = await apiLoginToken();
      //  localStorage.setItem('apiToken', token);
    }
    const response = await axios.get(
      `${apiURL}/api/v1/hotel/loc/${location.toLowerCase()}?limit=1000`,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );

    return response.data.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const GetTransportApiCal = async (location: string) => {
  try {
    if (!token || token === "" || token === undefined) {
      token = await apiLoginToken();
      //  localStorage.setItem('apiToken', token);
    }
    const response = await axios.get(
      `${apiURL}/api/v1/transport/loc/${location.toLowerCase()}?limit=100&sort=clientCost`,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const GetActivityApiCal = async (location: string) => {
  try {
    if (!token || token === "" || token === undefined) {
      token = await apiLoginToken();
      //  localStorage.setItem('apiToken', token);
    }
    const response = await axios.get(
      `${apiURL}/api/v1/activity/loc/${location.toLowerCase()}?limit=100`,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );

    return response.data.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const apiLoginToken = async () => {
  try {
    const response = await axios.post(`${apiURL}/api/v1/auth/login`, {
      email: 'luhar0002@gmail.com',
      password: 'hello122',
    });
    return response.data.token;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const loginApiCal = async (userData: loginInterface) => {
  try {
    let data = { email: userData.email, password: userData.password };
    const response = await axios.post(`${apiURL}/api/v1/auth/login`, data, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    return { success: false, error: err };
  }
};
export const genForgotPassOtp = async (email: string) => {
  try {
    let data = { email: email };
    const response = await axios.post(
      `${apiURL}/api/v1/auth/forgotpassword`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    return { success: false, error: err };
  }
};
export const verifyAndUpdatePass = async (
  email: string,
  password: string,
  otp: string
) => {
  try {
    let data = { email: email, password: password, otp: otp };
    const response = await axios.post(
      `${apiURL}/api/v1/auth/passverify`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    return { success: false, error: err };
  }
};

export const tempSignupApiCal = async (userData: loginInterface) => {
  try {
    let data = {
      name: userData.name,
      mobileNo: userData.mobileNo,
      email: userData.email,
      password: userData.password,
    };
    if (!token || token === "" || token === undefined) {
      token = await apiLoginToken();
    }
    const response = await axios.post(`${apiURL}/api/v1/registeruser`, data, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    console.log("res: ", response.data);
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    return { success: false, error: err };
  }
};

export const getAPIcall = async (url: string, options: { sort?: string }) => {
  try {
    if (!token || token === "" || token === undefined) {
      token = await apiLoginToken();
      //  localStorage.setItem('apiToken', token);
    }
    let formedUrl = `${apiURL}${url}?limit=100`;
    if (options.sort) {
      formedUrl += "&sort=" + options.sort;
    }
    // console.log("hotel, token: ", token);
    const response = await axios.get(formedUrl, {
      headers: { authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getAPIcallById = async (url: string, id: string) => {
  try {
    if (!token || token === "" || token === undefined) {
      token = await apiLoginToken();
      //  localStorage.setItem('apiToken', token);
    }
    const response = await axios.get(`${apiURL}${url}/${id}?limit=100`, {
      headers: { authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const postApiWithOutAuth = async (
  url: string,
  body: any,
  options: {}
) => {
  try {
    let formedUrl = `${apiURL}${url}`;
    const response = await axios.post(formedUrl, body);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const putApiWithOutAuth = async (
  url: string,
  body: any,
  tid: string
) => {
  try {
    if (!token || token === "" || token === undefined) {
      token = await apiLoginToken();
      //  localStorage.setItem('apiToken', token);
    }
    let formedUrl = `${apiURL}${url}/${tid}`;
    const response = await axios.put(formedUrl, body, {
      headers: { authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const verifyTokenStatus = async (token: string) => {
  try {
    const url = `${apiURL}/api/v1/token/${token}`;
    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
export const paymentRedirection = async (body: any) => {
  try {
    if (!token || token === "" || token === undefined) {
      token = await apiLoginToken();
      //  localStorage.setItem('apiToken', token);
    }
    let formedUrl = `${apiURL}/api/v1/processpayment`;
    const response = await axios.post(formedUrl, body, {
      headers: { authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
export const paymentRazorPayOrder = async (body: any) => {
  try {
    if (!token || token === "" || token === undefined) {
      token = await apiLoginToken();
      //  localStorage.setItem('apiToken', token);
    }
    let formedUrl = `${apiURL}/api/v1/rpg/order`;
    const response = await axios.post(formedUrl, body, {
      headers: { authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
export const paymentRazorPayVerifyAndUpdate = async (body: any) => {
  try {
    if (!token || token === "" || token === undefined) {
      token = await apiLoginToken();
      //  localStorage.setItem('apiToken', token);
    }
    let formedUrl = `${apiURL}/api/v1/transaction/verify`;
    const response = await axios.post(formedUrl, body, {
      headers: { authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
