import {transportDataInterface} from '../../Components/Common/Constant/StaticData/CustomInterface';

export interface transportReducerInterface {
    transportAPIData: transportDataInterface |null,
  }
const initialState: transportReducerInterface = {
    transportAPIData: null
};

const transportReducer = (state = initialState, action: any) => {
switch (action.type) {
    case 'SET_TRANSPORT_DATA':
    return {
        transportAPIData : action.transportAPIData
    };
    case 'RESET_TRANSPORT_DATA':
    return {
        transportAPIData: null
    };
    default:
    return state;
}
};

export default transportReducer;
  
  