import { objectStrAnyType } from '../InterfaceConst'
import {activitySchema, agentOfferSliderSchema, careerSchema, expenseSchema, flightBookSchema, hotelSchema, imageSchema, inquirySchema, itinerarySchema, sliderSchema, tUserSchema, tourBookConfirmSchema, tourBookSchema, transactionSchema, transportSchema, tripDetailsSchema, unsubscribeSchema, userSchema} from '../schema/emptySchemaData'
import {activitySchemaType} from '../schema/emptySchemaDataType'
export const collectionDetails:  Record<string, collectionDetailsType>= {

  activities: {
    url: "/api/v1/activity",
    viewColumn: ['_id', 'activity', 'location', 'cost', 'isActive'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'activity',
    initialValue: activitySchema,
    typeDetails: 'activitySchemaType'
  },
  agent_offer_sliders: {
    url: "/api/v1/agentslider",
    viewColumn: ['_id', 'name', 'image','isActive'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'agentOfferSlider',
    initialValue: agentOfferSliderSchema,
    typeDetails: 'agentOfferSliderSchemaType'
  },
  agent_registry_user:{
    url: "/api/v1/registeruser",
    viewColumn: ['_id', 'name', 'mobileNo','email'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: ['password', 'role'],
    colName: 'tUser',
    initialValue: tUserSchema,
    typeDetails: 'tUserSchemaType'
  },
  career: {
    url: "/api/v1/career",
    viewColumn: ['_id', 'title','isActive'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'career',
    initialValue: careerSchema,
    typeDetails: 'careerSchemaType'
  },
  flight: {
    url: "/api/v1/flight",
    viewColumn: ['_id', 'userId','isActive'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'flightBook',
    initialValue: flightBookSchema,
    typeDetails: 'flightBookSchemaType'
  },
  home_page_slider: {
    url: "/api/v1/slider",
    viewColumn: ['_id', 'name', 'image','isActive'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'sliderImages',
    initialValue: sliderSchema,
    typeDetails: 'sliderSchemaType'
  },
  hotel_price: {
    url: "/api/v1/hotel",
    viewColumn: ['_id', 'location', 'hotelName','isActive'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'hotel',
    initialValue: hotelSchema,
    typeDetails: 'hotelSchemaType'
  },
  inquiry: {
    url: "/api/v1/inquiry",
    viewColumn: ['_id', 'destination', 'userId','status'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'inquiry',
    initialValue: inquirySchema,
    typeDetails: 'inquirySchemaType'
  },
  image: {
    url: "/api/v1/image",
    viewColumn: ['_id', 'url', 'name'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'image',
    initialValue: imageSchema,
    typeDetails: 'imageSchemaType'
  },
  transport: {
    url: "/api/v1/transport",
    viewColumn: ['_id', 'location', 'vehicleType','isActive'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'transport',
    initialValue: transportSchema,
    typeDetails: 'transportSchemaType'
  },
  tour_booking_details: {
    url: "/api/v1/tour",
    viewColumn: ['_id', 'userId', 'bookingDate','tripName'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'tour',
    initialValue: tourBookSchema,
    typeDetails: 'tourBookSchemaType'
  },
  trip_card_tile_detail:{
    url: "/api/v1/tripcard",
    viewColumn: ['_id', 'location', 'shortDesc','isActive'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'tripDetails',
    initialValue: tripDetailsSchema,
    typeDetails: 'tripDetailsSchemaType'
  },
  unsubscribe: {
    url: "/api/v1/auth/unsubscribe",
    viewColumn: ['_id', 'email'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'unsubscribe',
    initialValue: unsubscribeSchema,
    typeDetails: 'unsubscribeSchemaType'
  },
  user:{
    url: "/api/v1/user",
    viewColumn: ['_id', 'name', 'role','email'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: ['password', 'role', 'resetPasswordToken','resetPasswordDate' ],
    colName: 'user',
    initialValue: userSchema,
    typeDetails: 'userSchemaType'
  },
  expense:{
    url: "/api/v1/expense",
    viewColumn: ['_id', 'name', 'category','cost'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'expense',
    initialValue: expenseSchema,
    typeDetails: 'expenseSchemaType'
  },
  tour_booking_confirm:{
    url: "/api/v1/tourconfirm",
    viewColumn: ['_id', 'tourId', 'pickup','sightseeing', 'activities'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'tourconfirm',
    initialValue: tourBookConfirmSchema,
    typeDetails: 'tourBookConfirmSchemaType'
  },
  transaction_initiated:{
    url: "/api/v1/transaction",
    viewColumn: ['transactionId', 'name', 'email','amount', 'status'],
    noEdit: ['_id','createdAt', '__v', 'name', 'email','amount','transactionId'],
    hideCol: [],
    colName: 'transaction',
    initialValue: transactionSchema,
    typeDetails: 'transactionSchemaType'
  },
  itinerary_pdf:{
    url: "/api/v1/itinerary",
    viewColumn: ['_id', 'name', 'pdfUrl', 'isActive'],
    noEdit: ['_id','createdAt', '__v'],
    hideCol: [],
    colName: 'itinerary',
    initialValue: itinerarySchema,
    typeDetails: 'itinerarySchemaType'
  },
  empty_structure:{
    url: "#",
    viewColumn: [],
    noEdit: [],
    hideCol: [],
    colName: '',
    initialValue: {name: '',
    mobile: '',
    email: '',
    amount: 0,},
    typeDetails: ''
  },
}
export type collectionDetailsType = {
  url: string,
  viewColumn: string[],
  noEdit: string[],
  hideCol: string[],
  colName: string,
  initialValue: objectStrAnyType,
  typeDetails: string
}
type collectionDataTypeHighLvl = {
  [key :string]: collectionDetailsType
}