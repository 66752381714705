import { PayloadAction, createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { objectStrAnyType } from "../../Components/Common/Constant/InterfaceConst";
import { adminDataInitial } from "../../Components/Common/Constant/schema/emptySchemaData";
import { addBulkCollectionData, addCollectionData, deleteCollectionData, fetchCollectionData, fetchDirectApiCollectionData, updateCollectionData } from "../actions/adminDataAction";
import { collectionDetails, collectionDetailsType } from "../../Components/Common/Constant/StaticData/collectionStaticInfo";

export type adminDataType ={
  // [key: string]: objectStrAnyType[],
  activity: objectStrAnyType[],
  agentOfferSlider: objectStrAnyType[],
  tUser: objectStrAnyType[],
  career: objectStrAnyType[],
  flightBook: objectStrAnyType[],
  sliderImages: objectStrAnyType[],
  hotel: objectStrAnyType[],
  inquiry: objectStrAnyType[],
  transport: objectStrAnyType[],
  tour: objectStrAnyType[],
  tripDetails: objectStrAnyType[],
  unsubscribe: objectStrAnyType[],
  user: objectStrAnyType[],
  expense: objectStrAnyType[],
  itinerary: objectStrAnyType[],
  error: string,
  loading: boolean,
  collectionActive: string,
  bulkError: objectStrAnyType[]
}
type propsType ={
  collectionName: string
  data : objectStrAnyType[],
}
type propsTypeSingle ={
  collectionName: string
  data : objectStrAnyType,
}
const adminDataSlicer = createSlice({
  name: 'collection',
  initialState: adminDataInitial ,
  reducers: {
    updateData: (state, action: PayloadAction<propsType>) => {
         const activeColl = action.payload.collectionName as keyof collectionDetailsType;
         let currCollection=  state[collectionDetails[activeColl].colName as keyof adminDataType] as objectStrAnyType[];
        currCollection = action.payload.data;
    },
    clearError: (state) => {
      state.error = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCollectionData.fulfilled, (state, action: PayloadAction<propsTypeSingle>) => {
        console.log("add: ", action.payload.data);
        const activeColl = action.payload.collectionName;  
        (state[collectionDetails[activeColl].colName as keyof adminDataType] as objectStrAnyType[]).push(action.payload.data);
        state.loading = false;
      })
      .addCase(addBulkCollectionData.fulfilled, (state, action: PayloadAction<propsTypeSingle>) => {
        const activeColl = action.payload.collectionName;  
        action.payload.data.success.map((row: objectStrAnyType) => {
                  (state[collectionDetails[activeColl].colName as keyof adminDataType] as objectStrAnyType[]).push(row)
        })
        state.bulkError = action.payload.data.errors;
        state.loading = false;
      })
      .addCase(updateCollectionData.fulfilled, (state, action: PayloadAction<propsType>) => {
         const activeColl = action.payload.collectionName as keyof collectionDetailsType;
        const currCollection=  state[collectionDetails[activeColl].colName as keyof adminDataType] as objectStrAnyType[];
        const indexVal = currCollection.findIndex(item  => item._id === (action.payload.data as any)._id);
        if(indexVal !== -1) currCollection[indexVal] = action.payload.data;
        (state[collectionDetails[activeColl].colName as keyof adminDataType] as objectStrAnyType[]) = currCollection;
        state.loading = false;
      })
      .addCase(deleteCollectionData.fulfilled, (state, action: PayloadAction<propsTypeSingle>) => {
        const activeColl = action.payload.collectionName; 
        const currCollection=  state[collectionDetails[activeColl].colName as keyof adminDataType] as objectStrAnyType[];
        const indexVal = currCollection.findIndex(item  => item._id === (action.payload.data as any)._id);
        currCollection.splice(indexVal,1);
        (state[collectionDetails[activeColl].colName as keyof adminDataType] as objectStrAnyType[]) = currCollection;
        state.loading = false;
      })
      .addMatcher(isFulfilled(fetchCollectionData, fetchDirectApiCollectionData), (state, action: PayloadAction<propsType>) => {
        const activeColl = action.payload.collectionName as keyof collectionDetailsType;  
        (state[collectionDetails[activeColl].colName as keyof adminDataType] as objectStrAnyType[]) = action.payload.data;
        state.loading = false;
      })
      .addMatcher(isRejected(fetchCollectionData, addCollectionData,
                updateCollectionData, deleteCollectionData, addBulkCollectionData), 
           (state, action: any) => {
            // update the error accordingly
          if (action.payload && action.payload.includes('WEBPACK_IMPORTED_MODULE')) state.error = "";
          else if (action.payload && !action.payload.includes('WEBPACK_IMPORTED_MODULE'))  state.error = action.payload;
          else  state.error = action.error.message;
          state.loading = false;
      })
      .addMatcher(isPending(fetchCollectionData, addCollectionData,
              updateCollectionData, deleteCollectionData, addBulkCollectionData), 
        (state) => {
        // Handle the error state if needed
        state.loading = true;
      });
    }
})
  
export const {updateData} = adminDataSlicer.actions;
export default adminDataSlicer.reducer;