import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type popupWrapperPropsType = {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  onSubmit?: (e: React.FormEvent) => void;
};

const PopupWrapper = ({
  title,
  isOpen,
  handleClose,
  children,
  onSubmit,
}: popupWrapperPropsType) => {
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <IconButton
          aria-label="close"
          sx={{ position: 'absolute', right: '8px', top: '8px', color: '#000' }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ margin: '0.5rem' }}>{children}</DialogContent>
        <DialogActions sx={{ margin: '0.5rem' }}>
          <Button variant="contained" onClick={handleClose} color="warning">
            Cancel
          </Button>
          {onSubmit && (
            <Button variant="contained" onClick={onSubmit} color="primary">
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopupWrapper;
