import React, { useState } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import CancelIcon from '@mui/icons-material/Cancel';
import Popup from 'react-animated-popup';
import { tripDataResponseInterface } from '../Common/Constant/StaticData/CustomInterface';
import { constColor } from '../Common/Constant/Constants';
interface tabInfoProps {
  type: number;
  tripData: tripDataResponseInterface;
}
const paperMargin = {
  marginLeft: '2rem',
  marginRight: '2rem',
  paddingTop: '1.5rem',
  paddingBottom: '1.5rem',
};
const iterStyle = {
  marginLeft: '2rem',
  marginRight: '2rem',
  display: 'flex',
  paddingTop: '1.5rem',
  paddingBottom: '1.5rem',
};
const TabInfo = ({ type, tripData }: tabInfoProps) => {
  const [visible, setVisible] = useState(false);
  const [dayWiseIndex, setDayWiseIndex] = useState(0);
  const hadlePopupClick = (val: boolean, dayIndex: number) => {
    setVisible(val);
    if (dayIndex !== -1) setDayWiseIndex(dayIndex);
  };
  const handleTab = (key: Number) => {
    switch (key) {
      case 0:
        return (
          <Typography align="justify" style={paperMargin}>
            {tripData['overview']}
          </Typography>
        );
      case 1:
        return (
          <div key={key.toString()}>
            <Grid style={iterStyle} container spacing={1}>
              {tripData['dayWise'].map((item, index) => (
                <Grid key={index} item xs={12}>
                  <Paper
                    style={{
                      backgroundColor: constColor.paperColor,
                      paddingLeft: '1rem',
                    }}
                    onClick={() => hadlePopupClick(true, index)}
                  >
                    <Typography variant="h6">{item[0]}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>

            <Popup
              style={{
                position: 'fixed',
                top: '20%',
                transform: 'translate(-50%, -50%)',
                overflow: 'scroll',
              }}
              visible={visible}
              onClose={() => hadlePopupClick(false, -1)}
            >
              <Typography variant="h6" align="center" gutterBottom>
                {tripData['dayWise'][dayWiseIndex][0]}
              </Typography>
              <Typography align="justify">
                {tripData['dayWise'][dayWiseIndex][1]}
              </Typography>
            </Popup>
          </div>
        );
      case 2:
        return (
          <List>
            {tripData['included'].map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <DoneIcon style={{ color: 'green' }} />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        );
      case 3:
        return (
          <List>
            {tripData['excluded'].map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <CloseIcon style={{ color: 'red' }} />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        );
      case 4:
        return (
          <List>
            {tripData['thingsToCarry'].map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <PlaylistAddIcon style={{ color: 'blue' }} />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        );
      case 5:
        return (
          <List>
            {tripData['cancellationPolicy'].map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <CancelIcon style={{ color: 'red' }} />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        );
      default:
        break;
    }
  };

  return (
    <>
      {/* <Typography style={paperMargin}>
            {overview}
        </Typography> */}
      {handleTab(type)}
    </>
  );
};

export default TabInfo;
