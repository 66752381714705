import { Description } from "@mui/icons-material"

export const hotelApiEmptyData = [
  {
    star: '0',
    hotelName: '',
    location: '',
    subLocation: '',
    nearBy: [''],
    distance: [''],
    washRoom: '',
    specialFeature : {},
    clientCost: [''],
    agentCost: [''],
    roomType: '',
    maxPax: '',
    conference: [''],
    isActive: false
  },
]

export const  activityApiEmptyData = [
  {
    location: '',
    subLocation: '',
    timing: '',
    activity: '',
    conditions: [''],
    cost: '',
    // executiveName: '', // not needed
    // contact: '', // not needed
    // email: '', // not needed
    // website: '', // not needed
    // photos: '',
    isActive: false
  },
]
export const  transportApiEmptyData = [
  {
    vehicleType: '',
    price: {},
    tourPrice: {},
    extraCharges: {},
    // executiveName: '', // not needed
    // contact: '', // not needed
    // email: '', // not needed
    // website: '', // not needed
    // photos: '',
    isActive: false
  },
]
export const tripDataApiEmptyData = {
  _id: "",
  location: "",
  subLocation: "",
  image: [""],
  thumbnail: [""],
  shortDesc: "",
  dayWise: [["", ""]],
  price: "",
  overview: "",
  included: [""],
  excluded : [""],
  thingsToCarry: [""],
  cancellationPolicy: [""],
  isActive: false
}
export const imageApiEmptyData = {
  _id: "",
  name: "",
  description: "",
  url: "",
  thumbnail: "",
  fileId: "", 
  isActive: false,
}
export const tripDataObjEmptyData = {
  "default":{
  _id: "",
  location: "",
  subLocation: "",
  image: [""],
  thumbnail: [""],
  shortDesc: "",
  dayWise: [["", ""]],
  price: "",
  overview: "",
  included: [""],
  excluded : [""],
  thingsToCarry: [""],
  cancellationPolicy: [""],
  isActive: false
  }
}
export const sliderImageEmptyData = [
  {
    _id: '111',
    image:
      'https://drive.google.com/uc?export=view&id=1_EJ-Vmgrm69jR8eDPSXplvAJehKUKP0e',
    caption: 'Goa',
    sortOrder: 1,
  }
]

export const itineraryApiEmptyData = {
  name: '',
  pdfUrl: '',
  description: '',
  isActive: false,
}
