import {tripObjectInterface} from '../../Components/Common/Constant/StaticData/CustomInterface';
interface tripCardActionInterface {
    tripCardAPIData: tripObjectInterface[];
}

export const setTripCardData = (payload: tripCardActionInterface) =>{
    return {
        type: "SET_TRIP_CARD_DATA",
        tripCardAPIData: payload.tripCardAPIData
    }
}

export const reSetTripCardData = () =>{
    return {
        type: "RESET_TRIP_CARD_DATA",
    }
}