import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

type NavBarProps = {
  menuList: String[];
  handleScroll: (indexId: number) => void;
};

const DrawerMenu = (props: NavBarProps) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Drawer
        PaperProps={{
          sx: { backgroundColor: ' rgba(9,82,121)' },
        }}
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          {props.menuList.map((item, index) => (
            <ListItem
              button
              key={index}
              divider
              onClick={() => {
                setOpenDrawer(false);
                props.handleScroll(index);
              }}
            >
              <ListItemText sx={{ color: 'white' }}>{item}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
        sx={{ marginLeft: 'auto', color: 'white' }}
      >
        <MenuRoundedIcon />
      </IconButton>
    </>
  );
};

export default DrawerMenu;
