import React from 'react';
import NavBar from '../NavBar';
import Footer from '../Footer';
import { childrenInterface } from '../Constant/InterfaceConst';
import Contact from '../ContactUs';
import WhatsAppButton from '../WhatsAppButton';

const BasicWrapper: React.FC<childrenInterface> = ({ children }) => {
  return (
    <>
      <NavBar />
      {children}
      <Contact />
      <WhatsAppButton />
      <Footer capName="TripYog" />
    </>
  );
};

export default BasicWrapper;
