import { styled } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { constColor } from '../Common/Constant/Constants';
import CustomImageDisplay from '../AdminImageView/CustomImageDisplay';
type dataType = {
  image: string;
  caption: string;
};

type CarouselProps = {
  data: dataType[];
  autoplay?: boolean;
  animationDuration?: 1 | 2 | 3 | number;
  // leftItem?:
  // | React.ReactHTMLElement<HTMLElement>
  // | React.ReactNode
  // | React.ReactChild;
  // rightItem?:
  // | React.ReactHTMLElement<HTMLElement>
  // | React.ReactNode
  // | React.ReactChild;
};

const ImageContainer = styled('div')(() => ({
  display: 'grid',
  placeItems: 'center',
  gritTemplateAreas: 'inner-div',
  width: '100%',
  filter:
    'drop-shadow(0 10 px 8 px rgba(0, 0, 0, 0.04)) drop-shadow(0 4 px 3 px rgba(0, 0, 0, 0.1))',
  position: 'relative',
}));

const activeItemStyle = {
  zIndex: '20',
  animation: '0.5s ease-in-out',
};
// const rightActiveItem = {
//     marginLeft: '24rem',
//     opacity: '0.7',
//     transform: 'scale(0.9, 0.9)',
//     zIndex: '-10'

// }
// const leftActiveItem = {
//     marginRight: '24rem',
//     opacity: '0.7',
//     transform: 'scale(0.9, 0.9)',
//     zIndex: '-10'

// }
const inactiveItems = {
  opacity: '0',
};

const InnerImage = styled('div')(() => ({
  gridArea: 'inner-div',
  // display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  transitionProperty: 'all',
  transitionDuration: '500ms',
  // flexDirection: 'column',
  // justifyContent: 'center',
  // alignItems: 'center',
  width: '100vw',
  //   borderRadius: '0.5rem',
  // cursor: 'pointer',
  // objectPosition: 'center',
  height: `calc(100vh - 4px)`,
}));

const rightArrow = {
  // marginLeft: '24rem',
  position: 'absolute' as 'absolute',
  top: '50%',
  right: '32px',
  // opacity: '0.5',
  fontSize: '3rem',
  transform: 'scale(0.9, 0.9)',
  zIndex: '10',
  color: constColor.textWhite,
};

const leftArrow = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '32px',
  // opacity: '0.5',
  fontSize: '3rem',
  transform: 'scale(0.9, 0.9)',
  zIndex: '10',
  color: constColor.textWhite,
};

const Carousel = ({
  data,
  autoplay = true,
  animationDuration = 6,
}: CarouselProps) => {
  const [activeItem, setActiveItem] = useState<number>(data.length > 2 ? 1 : 3);
  // const [onDragState, setOnDragState] = useState(0);

  // useEffect(() => {
  //   autoplay &&
  //     setTimeout(() => {
  //       handlenextSlide(true);
  //     }, animationDuration * 1000);
  // }, [activeItem]);

  const handlenextSlide = (fwd: boolean) => {
    if (fwd) {
      if (activeItem >= data.length - 1) setActiveItem(0);
      else setActiveItem(activeItem + 1);
    } else {
      if (activeItem === 0) setActiveItem(data.length - 1);
      else setActiveItem(activeItem - 1);
    }
  };
  return (
    <ImageContainer>
      {data.length > 1 &&
        data.map((item, index) => {
          return (
            <InnerImage
              style={index === activeItem ? activeItemStyle : inactiveItems}
              key={index}
            >
              {/* <img
                style={{ height: '100%' }}
                src={item.image}
                alt={item.caption}
                width="100%"
                // styles={{}}
              /> */}

              <CustomImageDisplay
                imageId={data[activeItem].image}
                height={1024}
                width={3000}
                isThumbnail={false}
              />
              {index === activeItem && (
                <div>
                  <div style={leftArrow} onClick={() => handlenextSlide(false)}>
                    <ArrowBackIosIcon />
                  </div>
                  <div style={rightArrow} onClick={() => handlenextSlide(true)}>
                    <ArrowForwardIosIcon />
                  </div>
                </div>
              )}
            </InnerImage>
          );
        })}
    </ImageContainer>
  );
};

export default Carousel;
