import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { constColor } from '../../Constant/Constants';

const WaveLoading: React.FC = () => {
  const [currentLetter, setCurrentLetter] = useState(0);
  const loadingText = 'TRIP YOG';
  const colors = [
    constColor.outlinedColor,
    constColor.outlinedColor,
    constColor.outlinedColor,
    constColor.outlinedColor,
    constColor.textWhite,
    constColor.logoBlue,
    constColor.logoBlue,
    constColor.logoBlue,
  ]; // Array of colors

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentLetter(
        (prevCurrentLetter) => (prevCurrentLetter + 1) % loadingText.length
      );
    }, 300); // Change 300 to adjust the speed of the wave

    return () => clearInterval(intervalId);
  }, [loadingText.length]);

  return (
    <Box
      sx={{
        position: 'fixed', // Overlay takes the whole screen
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center', // Center vertically
        justifyContent: 'center', // Center horizontally
        backgroundColor: '#e9f5db', // Semi-transparent black background
        zIndex: 1000, // High z-index to be above other content
      }}
    >
      <Typography variant="h4">
        {loadingText.split('').map((letter, index) => (
          <Box
            key={index}
            component="span"
            sx={{
              display: 'inline-block',
              transition: 'transform 0.3s',
              transform: index === currentLetter ? 'scale(1.6)' : 'none',
              color: colors[index % colors.length], // Apply color from the array
            }}
          >
            {letter}
          </Box>
        ))}
      </Typography>
    </Box>
  );
};

export default WaveLoading;
