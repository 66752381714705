import { Container } from '@mui/material';
import React from 'react';

type propsTypes = {
  height: string;
  text: string;
  textSize: string;
  containerColor: string;
  textColor: string;
};

const HorizontalBar = ({
  height,
  text,
  textSize,
  containerColor,
  textColor,
}: propsTypes) => {
  const styleConst = {
    container: {
      backgroundColor: containerColor,
      height: height,
      width: '100%',
    },
    textStyle: {
      color: textColor,
      fontSize: textSize,
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '8px',
      // padding: '0.5rem 0rem 0rem 0rem',
    },
  };
  return (
    <Container maxWidth={false} style={styleConst.container}>
      <div style={styleConst.textStyle}>{text}</div>
    </Container>
  );
};

export default HorizontalBar;
