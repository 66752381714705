import { createAsyncThunk } from "@reduxjs/toolkit";
import { objectStrAnyType } from "../../Components/Common/Constant/InterfaceConst";
import { rootStoreType } from "../reducers";
import { createAllApiCall, createBulkAllApiCall, deleteAllApiCall, softDeleteAllApiCall, getAllAPICall, updateAllApiCall } from "../../Components/ApiBackend/AgentApiCal";
import { collectionDetails } from "../../Components/Common/Constant/StaticData/collectionStaticInfo";
import { adminDataType } from "../reducers/adminDataReducer";

export const fetchDirectApiCollectionData = createAsyncThunk(
    'collection/fetch2',
    async (collectionName: string, thunkAPI) => {
        const state: rootStoreType = thunkAPI.getState() as rootStoreType;
        const apiToken = state.auth.authToken;
        const activeCollection = state.adminData.collectionActive;
        // if(activeCollection !== collectionName){
        //     state.adminData.collectionActive = collectionName;
        //     localStorage.setItem('activeCollection', collectionName);
        // }
        try{
            const getApiData = await getAllAPICall({
                apiEndPoint: `${collectionDetails[collectionName].url}`,
                tokenId: apiToken!,
            })
            return {collectionName, data: getApiData};
        } catch (error: any){
            return thunkAPI.rejectWithValue(error.message);
        }
    }
)

export const fetchCollectionData = createAsyncThunk(
    'collection/fetch',
    async (collectionName: string, thunkAPI) => {
        const state: rootStoreType = thunkAPI.getState() as rootStoreType;
        const apiToken = state.auth.authToken;
        try{
            const existingData = state.adminData[collectionDetails[collectionName].colName as keyof adminDataType];
            if (Array.isArray(existingData) && existingData.length > 0){
                return {collectionName, data: existingData};
            } else{
                const getApiData = await getAllAPICall({
                    apiEndPoint: `${collectionDetails[collectionName].url}`,
            tokenId: apiToken!,
                })
                return {collectionName, data: getApiData};
            }
        } catch (error: any){
            return thunkAPI.rejectWithValue(error.message);
        }
    }
)
type propsDataType ={
    collectionName: string, data: objectStrAnyType
}
export const addCollectionData = createAsyncThunk(
    'collection/add',
    async ({collectionName, data}: propsDataType, thunkAPI) => {
        try{
            const state: rootStoreType = thunkAPI.getState() as rootStoreType;
            const apiToken = state.auth.authToken;
            if(data && Object.keys(data).length === 0)  return thunkAPI.rejectWithValue("Still Loading...");
            const response = await createAllApiCall({
                apiEndPoint: `${collectionDetails[collectionName].url}`,
                tokenId: apiToken!,
                data: data!,
            });
            if(Object.keys(response).includes('error')){
                console.log("error")
                return thunkAPI.rejectWithValue(response.error);
            }
            return  {collectionName, data: response};
        } catch (error: any){
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const addBulkCollectionData = createAsyncThunk(
    'collection/addbulk',
    async ({collectionName, data}: {
        collectionName: string, data: objectStrAnyType[]
    }, thunkAPI) => {
        try{
            const state: rootStoreType = thunkAPI.getState() as rootStoreType;
            const apiToken = state.auth.authToken;
            if(data && Object.keys(data).length === 0)  return thunkAPI.rejectWithValue("Still Loading...");
            const response = await createBulkAllApiCall({
                apiEndPoint: `${collectionDetails[collectionName].url}/batchadd`,
                tokenId: apiToken!,
                data: data!,
            });
            // window.alert(`Success: ${response.success.length} \n Error: ${response.error.length} `)
            return  {collectionName, data: response};
        } catch (error: any){
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const updateCollectionData = createAsyncThunk(
    'collection/update',
    async ({collectionName, data}: propsDataType, thunkAPI) => {
        try{
            const state: rootStoreType = thunkAPI.getState() as rootStoreType;
            const apiToken = state.auth.authToken;
            if(data && Object.keys(data).length === 0)  return thunkAPI.rejectWithValue("Still Loading...");
            const response = await updateAllApiCall({
                apiEndPoint: `${collectionDetails[collectionName].url}/${(data as any)._id}`,
                tokenId: apiToken!,
                data: data,
                });
            return  {collectionName, data: response};
        } catch (error: any){
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const deleteCollectionData = createAsyncThunk(
    'collection/delete',
    async ({collectionName, data}: propsDataType, thunkAPI) => {
        try{
            const state: rootStoreType = thunkAPI.getState() as rootStoreType;
            const apiToken = state.auth.authToken;
            if(data && Object.keys(data).length === 0)  return thunkAPI.rejectWithValue("Still Loading...");
            console.log("del action bef: ",collectionDetails[collectionName].url)
            const response = await softDeleteAllApiCall({
                apiEndPoint: `${collectionDetails[collectionName].url}/${(data as any)._id}`,
                tokenId: apiToken!,
            });
            console.log("del action: ",response)
            return  {collectionName, data: response};
        } catch (error: any){
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCollectionDataReal = createAsyncThunk(
    'collection/delete/real',
    async ({collectionName, data}: propsDataType, thunkAPI) => {
        try{
            const state: rootStoreType = thunkAPI.getState() as rootStoreType;
            const apiToken = state.auth.authToken;
            if(data && Object.keys(data).length === 0)  return thunkAPI.rejectWithValue("Still Loading...");
            const response = await deleteAllApiCall({
                apiEndPoint: `${collectionDetails[collectionName].url}/${(data as any)._id}`,
                tokenId: apiToken!,
            });
            return  {collectionName, data: response};
        } catch (error: any){
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
// export const addCollectionData = (collectionData: propsType) => {
//     return{
//         type: 'UPDATE_DATA',
//         payload: { collectionName: collectionData.collectionName,data: collectionData.data } 
//     }    
// }