import React, { Suspense, useEffect } from 'react';
import { childrenInterface } from '../Constant/InterfaceConst';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { rootStoreType } from '../../../Redux/reducers';
import { logoutAction } from '../../../Redux/actions/authActions';
import { NavBarLogin } from '../NavBar';
import Contact from '../ContactUs';
import WhatsAppButton from '../WhatsAppButton';
import Footer from '../Footer';
import { verifyTokenStatus } from '../../ApiBackend/ApiCal';
import WaveLoading from '../Animation/Loading/WaveLoading';

type customInterface = {
  children: React.ReactNode;
  roleRequired: string[];
};

const AuthCheckWrapper = ({
  children,
  roleRequired,
}: customInterface): React.ReactElement | null => {
  const dispatch = useDispatch();
  const token = useSelector((state: rootStoreType) => state.auth.authToken);
  const roleUser = useSelector((state: rootStoreType) => state.auth.role);
  const navigate = useNavigate();
  const [renderNext, setRenderNext] = React.useState(false);
  const verifyTokenStatusApiCall = async () => {
    const resp = await verifyTokenStatus(token!);
    if (resp.status) {
      if (!roleRequired.includes(roleUser!)) return false;
      setRenderNext(resp.status);
    }
    return resp.status;
  };
  useEffect(() => {
    (async () => {
      // Check token is expired or not
      const correctAuthRole = await verifyTokenStatusApiCall();
      if (!correctAuthRole) {
        dispatch(logoutAction());
        navigate('/agentlogin');
      }
    })();
  }, [token]);

  return (
    <>
      {renderNext ? (
        <>
          <NavBarLogin />
          {children}
          <Contact />
          <WhatsAppButton />
          <Footer capName="TripYog" />
        </>
      ) : (
        <WaveLoading />
      )}
    </>
  );
};

export default AuthCheckWrapper;
