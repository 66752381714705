import { userReduxInterface } from "../../Components/Common/Constant/InterfaceConst";

export const loginAction = (userRedux: userReduxInterface) => {
    return {
      type: 'LOGIN',
      payload: { name: userRedux.name, authToken: userRedux.authToken, role: userRedux.role },
    };
  };
  
export const logoutAction = () => {
    return {
      type: 'LOGOUT',
    };
};
export const isLoginAction = () => {
    return {
      type: 'IS_LOGIN',
    };
};