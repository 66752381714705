import {hotelDataInterface} from '../../Components/Common/Constant/StaticData/CustomInterface';

export interface hotelReducerInterface {
    hotelAPIData: hotelDataInterface |null,
  }
const initialState: hotelReducerInterface = {
    hotelAPIData: null
};

const hotelReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_HOTEL_DATA':
        return {
            hotelAPIData : action.hotelAPIData
        };
        case 'RESET_HOTEL_DATA':
        return {
            hotelAPIData: null
        };
        default:
        return state;
    }
};

export default hotelReducer;
  
  