import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import useAnalytics from '../GA/useAnalytics';
import ReactGA from "react-ga";
// import Logo1 from '../Images/Logo1'

// Google Analytics

const WhatsAppButton = () => {
  const greetingMessage: string = "Hello there! 🤝 \nWhich Trip in your mind?"
  const curName = "WhatsAPPButton";
  const curAction = "User click WhatsAPP icon"
  const initialized = useAnalytics();
  
  const handleClick = () =>{
    // <GAEvent name={name} action={action} lable="wa"/>
    if(initialized){
    console.log("#2301")
    ReactGA.set({page: curName});
      ReactGA.pageview(curName!);
      ReactGA.event({
          category: "Visit_" + curName,
          action: curAction,
          label: 'WA'
      })
  }
    // GAEventSet(curName,curAction,"WA");
    // GAEvent({name:curName,action:curAction,lable:"WA"});
}
  return (
    // <div onClick={() => handleClick()}>
        <FloatingWhatsApp phoneNumber='+917042470111' 
                            accountName="TripYog"
                            allowEsc
                            allowClickAway
                            notification
                            notificationSound
                            // avatar='../../Resources/Images/logo.png'
                            // avatar="https://drive.google.com/uc?export=view&id=1ksmf5HOwqiYVf2U_9PO2-cuyiIREZrD1"
                            avatar="https://drive.google.com/uc?export=view&id=11Y3BFf5xsdT6X7lSOVUPq5HhBKjn8r_m"
                            statusMessage='Online'
                            chatMessage= {greetingMessage}
                            // chatMessage="Hello\n Book your travel experience\n Which Destination in your mind?"
                            onClick={handleClick}
                            />
    // </div>
  )
}

export default WhatsAppButton