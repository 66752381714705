import React from 'react';
import { InnerImage } from './innerImage';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { constColor } from '../Constant/Constants';
import { imageCarouselInterface } from '../Constant/InterfaceConst';
import CustomImageDisplay from '../../AdminImageView/CustomImageDisplay';

const styleConst = {
  activeItemStyle: {
    zIndex: '20',
    animation: '0.3s ease-in-out',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5rem',
    paddingBottom: '1rem',
  },
  calButtonBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    marginBottom: '0.5rem',
    alignItems: 'center',
  },
  rightArrow: {
    position: 'absolute' as 'absolute',
    top: '50%',
    right: '12px',
    fontSize: '3rem',
    transform: 'scale(0.9, 0.9)',
    zIndex: '10',
    color: constColor.textWhite,
  },
  leftArrow: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '12px',
    fontSize: '3rem',
    transform: 'scale(0.9, 0.9)',
    zIndex: '10',
    color: constColor.textWhite,
  },
  textBlinking: {
    animation: `$blinkingAnimation 1s infinite`,
    '@keyframes blinkingAnimation': {
      '0%': { opacity: 1 },
      '50%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
    color: constColor.outlinedColor,
  },
};

const SmallCarousel = ({ image, alt }: imageCarouselInterface) => {
  const [curImage, setCurImage] = React.useState(0);
  console.log('small image: ', image, curImage);
  const handlenextSlide = (fwd: boolean) => {
    if (image) {
      if (fwd) {
        if (curImage >= image.length - 1) setCurImage(0);
        else setCurImage(curImage + 1);
      } else {
        if (curImage === 0) setCurImage(image.length - 1);
        else setCurImage(curImage - 1);
      }
    }
  };
  return (
    <>
      <InnerImage>
        {/* <img
          src={image[curImage]}
          alt={alt}
          width="100%"
          // styles={{}}
        /> */}

        <CustomImageDisplay
          imageId={image[curImage]}
          height={700}
          width={1200}
          isThumbnail={false}
        />
        <div>
          <div
            style={styleConst.leftArrow}
            onClick={() => handlenextSlide(false)}
          >
            <ArrowBackIosIcon />
          </div>
          <div
            style={styleConst.rightArrow}
            onClick={() => handlenextSlide(true)}
          >
            <ArrowForwardIosIcon />
          </div>
        </div>
      </InnerImage>
    </>
  );
};

export default SmallCarousel;
