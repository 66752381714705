import { Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import TripCard from './TripCard';
import { tripObjectInterface } from '../Common/Constant/StaticData/CustomInterface';
import { useSelector } from 'react-redux';

type propsTypes = {
  // data: dataType[];
  isMobileDisplay: boolean;
};

const styleConst = {
  cardStyle: {
    // backgroundColor: '#252b40',
    padding: '10px 10px 10px 10px',
    // borderRadius: '0.5rem',
  },
};

const DisplayCardGrid = ({ isMobileDisplay }: propsTypes) => {
  const tripCards: tripObjectInterface = useSelector(
    (state: any) => state.tripCardData.tripCardAPIData
  );
  return (
    <div style={styleConst.cardStyle}>
      <Grid container spacing={2}>
        {tripCards &&
          Object.entries(tripCards).map(([key, item], index) => {
            return (
              <Grid key={item._id} item xs={isMobileDisplay ? 12 : 4}>
                <Link
                  to={`/pages/${item._id}/trips/${item.location}`}
                  style={{ textDecoration: 'none' }}
                >
                  <TripCard key={key} tripId={item._id} />
                </Link>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default DisplayCardGrid;
