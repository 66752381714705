import {tripObjectInterface} from '../../Components/Common/Constant/StaticData/CustomInterface';

export interface tripCardReducerInterface {
    tripCardAPIData: tripObjectInterface |null,
  }
const initialState: tripCardReducerInterface = {
    tripCardAPIData: null
};

const tripCardReducer = (state = initialState, action: any) => {
switch (action.type) {
    case 'SET_TRIP_CARD_DATA':
        return {
            tripCardAPIData : action.tripCardAPIData
        };
    case 'RESET_TRIP_CARD_DATA':
        return {
            tripCardAPIData: null
        };
    default:
        return state;
}
};

export default tripCardReducer;
  
  