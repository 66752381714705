import { combineReducers } from 'redux';
import authReducers, { AuthStateInterface } from './authReducer';
import calculatorReducer, { calculatorReducerInterface } from './calculatorReducer';
import activityReducer, { activityReducerInterface } from './activityReducer';
import transportReducer, { transportReducerInterface } from './transportReducer';
import hotelReducer, { hotelReducerInterface } from './hotelReducer';
import tripCardReducer, { tripCardReducerInterface } from './tripCardReducer';
import adminDataReducer, {adminDataType} from './adminDataReducer';
import { objectStrAnyType } from '../../Components/Common/Constant/InterfaceConst';
import agentOrdersReducer from './agentOrdersReducer';
import agentOfferSlideReducer from './agentOfferSlideReducer';

const rootReducers = combineReducers({ 
  auth: authReducers,
  calculatorData: calculatorReducer,
  activityData: activityReducer,
  transportData: transportReducer,
  hotelData: hotelReducer,
  tripCardData: tripCardReducer,
  adminData: adminDataReducer,
  agentOrdersData: agentOrdersReducer,
  agentOfferSlideData: agentOfferSlideReducer
});

export type rootStoreType = {
  auth: AuthStateInterface,
  calculatorData: calculatorReducerInterface,
  activityData: activityReducerInterface,
  transportData: transportReducerInterface,
  hotelData: hotelReducerInterface,
  tripCardData: tripCardReducerInterface
  adminData: adminDataType,
  agentOrdersData: objectStrAnyType[],
  agentOfferSlideData: objectStrAnyType[]
}

export default rootReducers;