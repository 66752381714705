import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Home";
import "./App.css";
import { setCalData } from "../../Redux/actions/calculatorDataAction";
import store from "../../Redux/store";
import Unsubscribe from "../Common/Unsubscribe";
import BasicWrapper from "../Common/Wrappers/BasicWrapper";
import AuthCheckWrapper from "../Common/Wrappers/AuthWrapper";
import ProductPage from "../ProductPage";
import Profile from "../Profile";
import WaveLoading from "../Common/Animation/Loading/WaveLoading";
import PaymentStatus from "../Common/PaymentStatus";
// import AdminDashboard from '../AdminDashboard';
const AdminDashboard = lazy(() => import("../AdminDashboard"));
// const Profile = lazy(() => import('../Profile'));
// import AgentLogin from '../AgentLogin';
// const AgentLogin = lazy(() =>
//   waitTime(5000).then(() => import('../AgentLogin'))
// );
const AgentLogin = lazy(() => import("../AgentLogin"));

// import PageNotFound from '../Common/PageNotFound'; //const PageNotFound = lazy(() => import('../Common/PageNotFound').then(module => {return {default: module.PageNotFound}}));
const PageNotFound = lazy(() => import("../Common/PageNotFound"));
// const ProductPage = lazyLoad('../ProductPage');
// import Redirect from '../Common/Redirect/Redirect';
const Redirect = lazy(() => import("../Common/Redirect/Redirect"));
// import PriceCalculator from '../PriceCalculator';
const PriceCalculator = lazy(() => import("../PriceCalculator"));
// import MyDashboard from '../MyDashboard';
const MyDashboard = lazy(() => import("../MyDashboard"));
const SpecialMessage = lazy(() => import("../Common/SpecialMessage"));
function App() {
  const calData = localStorage.getItem("calData") || "";
  const roomsData = localStorage.getItem("roomsData") || "";
  const nights = localStorage.getItem("nights") || "";
  const isDataStored = localStorage.getItem("isDataStored") || "";
  if (calData) {
    const calDataObj = JSON.parse(calData);
    store.dispatch(
      setCalData({
        calData: calDataObj,
        roomsData: roomsData,
        nights: nights,
        isDataStored: isDataStored,
      })
    );
  }
  return (
    <Suspense fallback={<WaveLoading />}>
      <Routes>
        <Route
          path="/"
          element={
            <BasicWrapper>
              <Home />
            </BasicWrapper>
          }
        />
        <Route
          path="/agent/pages/:id/trips/:name/pricecalculator"
          element={
            <AuthCheckWrapper roleRequired={["agent"]}>
              <PriceCalculator />
            </AuthCheckWrapper>
          }
        />
        <Route
          path="/payment/status/"
          element={
            <BasicWrapper>
              <PaymentStatus />
            </BasicWrapper>
          }
        />
        <Route
          path="/paymentstatus"
          element={
            <BasicWrapper>
              <SpecialMessage />
            </BasicWrapper>
          }
        />
        <Route
          path="/pages/:id/trips/:name/pricecalculator"
          element={
            <BasicWrapper>
              <PriceCalculator />
            </BasicWrapper>
          }
        />
        <Route
          path="/pages/:id/trips/:name"
          element={
            <BasicWrapper>
              <ProductPage />
            </BasicWrapper>
          }
        />
        <Route
          path="/mydashboard"
          element={
            <AuthCheckWrapper roleRequired={["agent"]}>
              <MyDashboard />
            </AuthCheckWrapper>
          }
        />
        <Route
          path="/agentlogin"
          element={
            <BasicWrapper>
              <AgentLogin />
            </BasicWrapper>
          }
        />
        <Route
          path="/edashboard"
          element={
            <AuthCheckWrapper roleRequired={["admin", "publisher"]}>
              <AdminDashboard />
            </AuthCheckWrapper>
          }
        />
        <Route
          path="/profile"
          element={
            <AuthCheckWrapper roleRequired={["agent"]}>
              <Profile />
            </AuthCheckWrapper>
          }
        />
        <Route
          path="/redirect/:url"
          element={
            <BasicWrapper>
              <Redirect />
            </BasicWrapper>
          }
        />
        <Route
          path="/pagenotfound"
          element={
            <BasicWrapper>
              <PageNotFound />
            </BasicWrapper>
          }
        />
        <Route
          path="/users/unsubscribe"
          element={
            <BasicWrapper>
              <Unsubscribe />
            </BasicWrapper>
          }
        />
        <Route
          path="/users/unsubscribe/:email"
          element={
            <BasicWrapper>
              <Unsubscribe />
            </BasicWrapper>
          }
        />
        <Route
          path="*"
          element={
            <BasicWrapper>
              <PageNotFound />
            </BasicWrapper>
          }
        />
        {/* </WrapperGA> */}
      </Routes>
    </Suspense>
  );
}

export default App;

function waitTime(time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}
