import { Box, Typography } from '@mui/material';
import React from 'react';
import { constColor } from '../Common/Constant/Constants';
import { Link } from 'react-router-dom';
import './newLaunch.css';
const styleConst = {
  box: {
    margin: 'auto',
    padding: '1rem',
    backgroundColor: '#023047',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTypo: {
    textDecoration: 'none',
  },
};
interface customInterface {
  name: string;
  url: string;
}
const NewLaunchBanner = ({ name, url }: customInterface) => {
  return (
    <Box sx={styleConst.box}>
      <div className="catchy-banner">
        <Link to={url}>
          <Typography variant="h5" color={constColor.outlinedColor}>
            New Launch
          </Typography>
        </Link>
      </div>
      <Link to={url}>
        <Typography variant="h6" color="white">
          {name}
        </Typography>
      </Link>
    </Box>
  );
};

export default NewLaunchBanner;
