import React, { useState, useRef, useEffect } from 'react';
import { Grid, useTheme, useMediaQuery, Typography, Box } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useAnalytics from '../Common/GA/useAnalytics';
import ProductDetails from './ProductDetails';
import ReactGA from 'react-ga';
import { tripDataResponseInterface } from '../Common/Constant/StaticData/CustomInterface';
import { getAPIcallById } from '../ApiBackend/ApiCal';
import { tripDataApiEmptyData } from '../Common/Constant/StaticData/APIEmptyData';
import { constColor, showCalculatorOfLoc } from '../Common/Constant/Constants';
// import { InnerImage } from '../Common/CustomStyleComp/innerImage';
import SmallCarousel from '../Common/CustomStyleComp/SmallCarousel';

const styleConst = {
  activeItemStyle: {
    zIndex: '20',
    animation: '0.3s ease-in-out',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5rem',
    paddingBottom: '1rem',
  },
  calButtonBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    marginBottom: '0.5rem',
    alignItems: 'center',
  },
  rightArrow: {
    position: 'absolute' as 'absolute',
    top: '50%',
    right: '12px',
    fontSize: '3rem',
    transform: 'scale(0.9, 0.9)',
    zIndex: '10',
    color: constColor.textWhite,
  },
  leftArrow: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '12px',
    fontSize: '3rem',
    transform: 'scale(0.9, 0.9)',
    zIndex: '10',
    color: constColor.textWhite,
  },
  textBlinking: {
    animation: `$blinkingAnimation 1s infinite`,
    '@keyframes blinkingAnimation': {
      '0%': { opacity: 1 },
      '50%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
    color: constColor.outlinedColor,
  },
};

const ProductPage = () => {
  const defaultRender = useRef(null);
  const theme = useTheme();
  const isMobileDisplay = useMediaQuery(theme.breakpoints.down('md'));
  const initialized = useAnalytics();

  const { id, name } = useParams();
  const curAction = 'User click on ' + name;
  useEffect(() => {
    if (initialized) {
      console.log('#16010705');
      ReactGA.set({ page: name });
      ReactGA.pageview(name!);
      ReactGA.event({
        category: 'Visit_' + name,
        action: curAction,
        label: 'page',
      });
    }
  }, [name, curAction]);
  // }
  // to reset scroll
  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    // Add event listener for beforeunload to detect page reload
    window.addEventListener('beforeunload', handleScrollToTop);

    // Clean up the event listener
    return () => {
      window.removeEventListener('beforeunload', handleScrollToTop);
    };
  }, []);
  const newId: string = id ? id : '';
  const [tripData, setTripData] =
    useState<tripDataResponseInterface>(tripDataApiEmptyData);
  const fetchTripDataByID = async () => {
    try {
      const tripApiData = await getAPIcallById('/api/v1/tripcard', newId);
      setTripData(tripApiData);
    } catch (error) {
      console.log('productPage error');
    }
  };
  useEffect(() => {
    fetchTripDataByID();
  }, []);

  return (
    <>
      <div ref={defaultRender}>
        {tripData._id === '' ? (
          <>
            <br />
            <br />
            <br />
            <br />
            <Typography variant="h4">Data is Loading....</Typography>
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={isMobileDisplay ? 12 : 6}>
              <SmallCarousel
                image={tripData['image']}
                alt={tripData['location']}
              />
            </Grid>
            <Grid item xs={isMobileDisplay ? 12 : 6}>
              <Box
                sx={{
                  ...styleConst.info,
                  alignItems: 'center',
                }}
              >
                <Typography variant="h3">{tripData['location']}</Typography>
              </Box>
              <ProductDetails key={name} tripData={tripData} />
              {showCalculatorOfLoc.includes(tripData._id) ? (
                <Box sx={styleConst.calButtonBox}>
                  <Link
                    to={`/pages/${tripData._id}/trips/${tripData.location}/pricecalculator`}
                  >
                    <Typography variant="h6" sx={styleConst.textBlinking}>
                      Try New {tripData.location.toUpperCase()} Package
                      Calculator
                    </Typography>
                  </Link>
                </Box>
              ) : null}
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default ProductPage;
