import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { agentOrdersDataInitial } from "../../Components/Common/Constant/schema/emptySchemaData";
import { objectStrAnyType } from "../../Components/Common/Constant/InterfaceConst";
import { fetchAgentOrders } from "../actions/agentOrdersAction";


const agentOrdersSlicer = createSlice({
    name: 'agentOrders',
    initialState: agentOrdersDataInitial,
    reducers: {
        updateData: (state, action: PayloadAction<objectStrAnyType[]>) => {
            state.agentOrdersData = action.payload;
        }        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAgentOrders.fulfilled, (state, action:PayloadAction<objectStrAnyType[]> ) => {
            state.agentOrdersData = action.payload;
        })
    }
})

export const {updateData} = agentOrdersSlicer.actions;
export default agentOrdersSlicer.reducer;
