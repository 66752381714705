import { TextField, styled } from '@mui/material';
import { constColor } from '../Constant/Constants';

export const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: constColor.outlinedColor,
      borderWidth: 2,
    },
    '&:hover fieldset': {
      borderColor: 'teal',
    },
  },
});
