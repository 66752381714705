import React, { useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import HorizontalBar from './HorizontalBar';
import DisplayCardGrid from './DisplayCardGrid';
import { getAPIcall } from '../ApiBackend/ApiCal';
import { setTripCardData } from '../../Redux/actions/tripCardAction';
import store from '../../Redux/store';
import { constColor } from '../Common/Constant/Constants';
import NewLaunchBanner from './NewLaunchBanner';

const TripDisplayCard = () => {
  const tripDetailsAPI = async () => {
    try {
      const tripApiData = await getAPIcall('/api/v1/tripcard', {});
      if (tripApiData && tripApiData.length > 0) {
        const tripDetailsObj = tripApiData.reduce(
          (prv_obj: any, trip_obj: { [x: string]: any }) => {
            return Object.assign(prv_obj, { [trip_obj['_id']]: trip_obj });
          },
          {}
        );
        store.dispatch(setTripCardData({ tripCardAPIData: tripDetailsObj }));
      }
    } catch (error) {
      console.log('error');
    }
  };
  useEffect(() => {
    tripDetailsAPI();
  }, []);

  const theme = useTheme();
  const isMobileDisplay = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div id="top-destination">
      <NewLaunchBanner
        name="Goa Trip Package Calculator"
        url="/pages/6499df325ccd39608e34be6f/trips/Goa/pricecalculator"
      />
      <HorizontalBar
        height="4rem"
        text="Top Trips Destination"
        textSize="200%"
        textColor={constColor.textWhite}
        containerColor={constColor.horizontalBar}
      />
      <DisplayCardGrid isMobileDisplay={isMobileDisplay} />
    </div>
  );
};

export default TripDisplayCard;
