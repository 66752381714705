import {formDataTypeInterface} from '../../Components/Common/Constant/StaticData/CustomInterface';
interface calculatorActionInterface {
    calData: formDataTypeInterface;
    roomsData: string;
    nights: string;
    isDataStored: string;
}
export const setCalData = (payload: calculatorActionInterface) =>{
    return {
        type: "SET_CAL_DATA",
        isDataStored: payload.isDataStored,
        calData: payload.calData,
        roomsData: payload.roomsData,
        nights: payload.nights
    }
}

export const reSetCalData = () =>{
    return {
        type: "RESET_CAL_DATA",
    }
}