import React, { useState } from 'react';
import {
  collectionDetails,
  collectionDetailsType,
} from '../Common/Constant/StaticData/collectionStaticInfo';
import { CssTextField } from '../Common/CustomStyleComp/customStyle';
import { objectStrAnyType } from '../Common/Constant/InterfaceConst';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import NotificationSnackBar from '../Common/NotificationSnackbar/NotificationSnackBar';
import { HelpOutline } from '@mui/icons-material';

type renderPropsType = {
  keyColl: string;
  valueColl: any;
  collectionName: string;
  curDataTable: objectStrAnyType;
  setCurDataTable: Function;
  viewIsEnable?: boolean;
};
export const RenderDynamicField = ({
  keyColl,
  valueColl,
  collectionName,
  curDataTable,
  setCurDataTable,
  viewIsEnable,
}: renderPropsType) => {
  // Error and Snack bar details
  const [showError, setShowError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('Something Went Wrong');
  const [errorType, setErrorType] = useState<'error' | 'success' | 'warning'>(
    'warning'
  );

  const collectionData: collectionDetailsType = (collectionDetails as any)[
    collectionName
  ];
  const [collectionError, setCollectionError] = useState('');
  const [collectionErrorStatement, setCollectionErrorStatement] = useState('');
  const handleObjectType = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      // Attempt to parse the input as JSON to validate it
      setCurDataTable({
        ...curDataTable,
        [keyColl]: JSON.parse(event.target.value),
      });
      setCollectionError('');
      console.log('no errro');
      setCollectionErrorStatement('');
    } catch (error) {
      console.log('errro');
      setCollectionError(event.target.id);
      setCollectionErrorStatement('Invalid JSON');
      setCurDataTable({
        ...curDataTable,
        [keyColl]: event.target.value,
      });
    }
  };
  if (curDataTable === null) return null;
  if (collectionData.hideCol.includes(keyColl)) return null;
  else if (typeof valueColl === 'string' || typeof valueColl === 'number') {
    //Space for String and Number Data type
    return (
      <>
        {collectionData.hideCol.includes(keyColl) ? null : (
          <CssTextField
            label={keyColl.toUpperCase()}
            value={curDataTable[keyColl]}
            variant="outlined"
            rows={Math.ceil(curDataTable[keyColl].toString().length / 25)}
            onChange={(event) =>
              setCurDataTable({
                ...curDataTable,
                [keyColl]: event.target.value,
              })
            }
            color="warning"
            disabled={
              viewIsEnable || collectionData.noEdit.includes(keyColl)
                ? true
                : false
            }
            InputProps={{
              endAdornment: (
                <Tooltip title="String or Number">
                  <IconButton>
                    <HelpOutline />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        )}
      </>
    );
  } else if (typeof valueColl === 'boolean') {
    return (
      <>
        <Checkbox
          checked={curDataTable[keyColl]}
          onChange={(event) => {
            setCurDataTable({
              ...curDataTable,
              [keyColl]: event.target.checked,
            });
          }}
          inputProps={{ 'aria-label': 'Checkbox' }}
          disabled={
            viewIsEnable || collectionData.noEdit.includes(keyColl)
              ? true
              : false
          }
        />
        <label>{keyColl.toUpperCase()}</label>
      </>
    );
  } else if (Array.isArray(valueColl)) {
    //Space for Array type
    return (
      <CssTextField
        label={keyColl.toUpperCase()}
        value={curDataTable[keyColl].join(', ')}
        variant="outlined"
        multiline
        rows={Math.ceil(curDataTable[keyColl].join(', ').length / 25)}
        onChange={(event) =>
          setCurDataTable({
            ...curDataTable,
            [keyColl]: event.target.value.split(',').map((item) => item.trim()),
          })
        }
        color="warning"
        disabled={
          viewIsEnable || collectionData.noEdit.includes(keyColl) ? true : false
        }
        InputProps={{
          endAdornment: (
            <Tooltip title="List">
              <IconButton>
                <HelpOutline />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
    );
  } else if (typeof valueColl === 'object') {
    // This space for object
    return (
      // <div style={{ height: '40px' }}>
      <>
        <CssTextField
          id={keyColl}
          label={keyColl.toUpperCase()}
          multiline
          rows={Math.ceil(JSON.stringify(curDataTable[keyColl]).length / 25)}
          sx={{ minHeight: '10rem' }}
          value={JSON.stringify(curDataTable[keyColl] || {})}
          variant="outlined"
          onChange={handleObjectType}
          color="warning"
          disabled={
            viewIsEnable || collectionData.noEdit.includes(keyColl)
              ? true
              : false
          }
          error={collectionError === keyColl}
          helperText={
            collectionError === keyColl ? collectionErrorStatement : ''
          }
          InputProps={{
            endAdornment: (
              <Tooltip title="Object">
                <IconButton>
                  <HelpOutline />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
        <NotificationSnackBar
          message={errorText}
          type={errorType}
          open={showError}
          onClose={() => setShowError(false)}
        />
      </>
      // </div>
    );
  }
  return null;
};
