import { styled } from '@mui/material';

export const InnerImage = styled('div')(() => ({
  gridArea: 'inner-div',
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  transitionProperty: 'all',
  transitionDuration: '500ms',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  borderRadius: '1rem',
  cursor: 'pointer',
  objectPosition: 'center',
  minHeight: '25rem',
  maxHeight: '35rem',
  marginTop: '5rem',
  marginBottom: '1rem',
  marginLeft: '.5rem',
  boxShadow: '5px 5px 5px rgba(0,0,0,.5)',
  aspectRatio: '4 / 3',
}));
