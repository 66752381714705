import React from 'react';
import Slider from '../SliderMain';
import TripDisplayCard from '../TripDisplayCard';

const LandingPage = () => {
  return (
    <>
      <Slider />
      <TripDisplayCard />
    </>
  );
};

export default LandingPage;
