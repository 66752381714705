import React, { useState, useEffect } from "react"; // Import `useState` and `useEffect`
import { useSearchParams } from "react-router-dom";
import { paymentRazorPayVerifyAndUpdate } from "../../ApiBackend/ApiCal";
import { Box, Typography } from "@mui/material";

const PaymentStatus = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status") || "pending";
  const orderId = searchParams.get("orderId") || "";
  const paymentId = searchParams.get("paymentId") || "";
  const signature = searchParams.get("signature") || "";

  const [paymentStatus, setPaymentStatus] = useState("pending"); // State for result

  useEffect(() => {
    const verifyAndUpdatePayment = async () => {
      if (orderId !== "" && signature !== "" && paymentId !== "") {
        try {
          await paymentRazorPayVerifyAndUpdate({
            status,
            orderId,
            paymentId,
            signature,
          });
          setPaymentStatus("success");
        } catch (error) {
          setPaymentStatus("failed");
        }
      } else {
        await paymentRazorPayVerifyAndUpdate({
          status: "failed",
          orderId,
          paymentId,
          signature,
        });
        setPaymentStatus("failed");
      }
    };

    verifyAndUpdatePayment();
  }, [orderId, signature, paymentId, status]); // Dependency array for useEffect

  return (
    <>
      <br /> <br /> <br /> <br /> <br />
      <Box sx={{ margin: "1rem" }}>
        {!["success", "failed"].includes(paymentStatus) && (
          <Typography variant="h5">Processing Payment...</Typography>
        )}
        {paymentStatus === "success" && (
          <Typography variant="h5">Thank You Payment: success</Typography>
        )}
        {paymentStatus === "failed" && (
          <Typography variant="h5">
            Your Payment Failed, Please Try Again
          </Typography>
        )}
      </Box>
    </>
  );
};

export default PaymentStatus;
