import { activityApiEmptyData } from '../../Components/Common/Constant/StaticData/APIEmptyData';
import {activityDataInterface} from '../../Components/Common/Constant/StaticData/CustomInterface';
// import { fetchActivityData } from '../actions/activityAction';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface activityReducerInterface {
    activityAPIData: activityDataInterface[] |null,
  }
const initialState: activityReducerInterface = {
    activityAPIData: activityApiEmptyData
};

// const activitySlice = createSlice({
//     name: 'activity',
//     initialState,
//     reducers: {
//         reSetActivityData: (state) => {
//             state.activityAPIData = null;
//         }
//     },
//     extraReducers: (builder) => {
//         builder
//             .addCase(fetchActivityData.pending, (state) => {
//                 // You can handle loading state here if needed
//             })
//             .addCase(fetchActivityData.fulfilled, (state, action: PayloadAction<activityDataInterface[]>) => {
//                 state.activityAPIData = action.payload;
//             })
//             .addCase(fetchActivityData.rejected, (state, action) => {
//                 // Handle the error state if needed
//             });
//         }
// })
const activityReducer = (state = initialState, action: any) => {
switch (action.type) {
    case 'SET_ACTIVITY_DATA':
    return {
        activityAPIData : action.activityAPIData
    };
    case 'RESET_ACTIVITY_DATA':
    return {
        activityAPIData: null
    };
    default:
    return state;
}
};
export default activityReducer;
// export const {reSetActivityData} = activitySlice.actions;
// export default activitySlice.reducer;
  
  