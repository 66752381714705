import axios, { AxiosError } from 'axios'; 
import { loginInterface, objectStrAnyType } from '../Common/Constant/InterfaceConst';
import { useSelector } from 'react-redux';
import { rootStoreType } from '../../Redux/reducers';
import { apiURL } from '../Common/Constant/Constants';

type getApiPropsType ={
    apiEndPoint: string, tokenId: string, data?: objectStrAnyType
}
// Get all api
export const getAllAPICall = async({apiEndPoint, tokenId, data}: getApiPropsType) => {
    try{
        const finalApiUrl = `${apiURL}${apiEndPoint}`
        
        const response = await axios.get(finalApiUrl, { 
        headers: {'authorization' : `Bearer ${tokenId}`
        } })
        return response.data.data;
  } catch (error : any){
    if (error.response) {   
      return { error: error.response.data.error };
    }
    return {error: error.message};
  }
}
// Post all api
export const createAllApiCall = async({apiEndPoint, tokenId, data}: getApiPropsType) => {
    try{
        const finalApiUrl = `${apiURL}${apiEndPoint}`
        
        const response = await axios.post(finalApiUrl, data, { 
          headers: {'authorization' : `Bearer ${tokenId}`
        } })
        return response.data.data;
      } catch (error : any){
        if (error.response) {   
          return { error: error.response.data.error };
        }
        return {error: error.message};
      }
}
// Post all api
export const createBulkAllApiCall = async({apiEndPoint, tokenId, data}: getApiPropsType) => {
  try{
      const finalApiUrl = `${apiURL}${apiEndPoint}`
      
      const response = await axios.post(finalApiUrl, data, { 
        headers: {'authorization' : `Bearer ${tokenId}`
      } })
      return response.data.data;
    } catch (error : any){
      if (error.response) {   
        return { error: error.response.data.error };
      }
      return {error: error.message};
    }
}
// Update all api
export const updateAllApiCall = async({apiEndPoint, tokenId, data}: getApiPropsType) => {
    try{
        const finalApiUrl = `${apiURL}${apiEndPoint}`
        
        const response = await axios.put(finalApiUrl, data, { 
          headers: {'authorization' : `Bearer ${tokenId}`
        } })
        return response.data.data;
      } catch (error : any){
        if (error.response) {   
          return { error: error.response.data.error };
        }
        return {error: error.message};
      }
}
// Delete
export const deleteAllApiCall = async({apiEndPoint, tokenId, data}: getApiPropsType) => {
    try{
        const finalApiUrl = `${apiURL}${apiEndPoint}`
        
        const response = await axios.delete(finalApiUrl, { 
          headers: {'authorization' : `Bearer ${tokenId}`
        } })
        return response.data.data;
      } catch (error : any){
        if (error.response) {   
          return { error: error.response.data.error };
        }
        return {error: error.message};
      }
}
// Soft Delete
export const softDeleteAllApiCall = async({apiEndPoint, tokenId, data}: getApiPropsType) => {
  try{
      const finalApiUrl = `${apiURL}${apiEndPoint}`
      
      const response = await axios.put(finalApiUrl, {isDeleted:true}, { 
        headers: {'authorization' : `Bearer ${tokenId}`
      } })
      console.log("delete: ", response);
      return response.data.data;
    } catch (error : any){
      console.log("delete: error");
      if (error.response) {   
        return { error: error.response.data.error };
      }
      return {error: error.message};
    }
}
