import { Button } from '@mui/material';
import React from 'react';
import { pdfjs } from 'react-pdf';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { rootStoreType } from '../../Redux/reducers';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url
// ).toString();

const fetchVoucher = async (token: string) => {
  try {
    const res = await axios.get(
      'http://localhost:5968/api/v1/doc/voucher/client/textId',
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    return res;
  } catch (error) {
    console.error('Failed to fetch voucher: ', error);
  }
};

const Profile = () => {
  const agentToken = useSelector(
    (state: rootStoreType) => state.auth.authToken
  );
  if (agentToken !== null) {
    const voucherDetails = fetchVoucher(agentToken);
    console.log('voucher: ', voucherDetails);
  }
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Button>view</Button>
      <Button>Download</Button>
    </>
  );
};

export default Profile;
