import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

// const useAnalytics = ({category, action, label}:getProps) => {
const useAnalytics = () => {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    // If environment is localhost, we initialize Analytics
    // if (window.location.href.includes('localhost')){
    ReactGA.initialize('UA-251670900-2'); // Live
    // ReactGA.initialize('UA-251670900-1'); //local
    console.log('#2101');
    // }
    //  Update state to true. If it happens onece
    setInitialized(true);
  }, []);
  return initialized;
};

export default useAnalytics;

// tripyog_Website_live - GA4
// 361680846
//
