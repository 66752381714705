import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import rootReducers from "./reducers";
import thunk from 'redux-thunk';


const store = configureStore({
    reducer: rootReducers, 
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
} )

export type AppDispatch = typeof store.dispatch;

export default store;