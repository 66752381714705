import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Grid,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ToolBar from '@mui/material/Toolbar';
import Logo1 from '../Images/Logo1';
import DrawerMenu from './DrawerMenu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { constColor } from '../Constant/Constants';
import { useSelector } from 'react-redux';
import store from '../../../Redux/store';
import { logoutAction } from '../../../Redux/actions/authActions';

const gradientColor = {
  backgroundImage:
    'linear-gradient(90deg,rgba(0,212,255,1)0%, rgba(9,82,121,1) 48%,  rgba(2,0,36,1)  100%)',
};
const styleConst = {
  textIndicatorColor: {
    '& .MuiTabs-indicator': {
      color: constColor.outlinedColor,
    },
  },
};

const NavBarTransparent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isDrawerNeeded = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = useState(0);
  const [userLogin, setUserLogin] = useState(
    useSelector<boolean>((state: any) => state.auth.isLogin)
  );
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [navBarTrans, setNavBarTrans] = useState(false);
  // const ref = useRef(null);
  const menuList = ['Home', 'Top Destination', 'Contact Us', 'Login'];
  // const menuListRef = ["/#home", "/#top-destination", "/#contact-us"];
  const menuListRef = ['home', 'top-destination', 'contact-us', 'login'];
  const handleScroll = (indexId: number) => {
    const elementID = menuListRef[indexId];
    if (elementID === menuListRef[3]) {
      if (userLogin) {
        store.dispatch(logoutAction());
        navigate('/');
      } else {
        navigate('/agentlogin');
      }
    } else {
      const elem = document.getElementById(elementID);
      elem?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    // ref.current?.scrollIntoView({behavior: 'smooth'})
    // window.scrollTo({
    //     top: reff.offsetTop,
    //     left: 0,
    //   behavior: "smooth",
    // });
  };
  const location = useLocation();
  useEffect(() => {
    const handleScrollNew = () => {
      const scrollPosition = window.scrollY;
      const carouselHeight = window.innerHeight;
      /* Calculate the height of your carousel component */

      if (location.pathname === '/') {
        setNavBarTrans(scrollPosition < carouselHeight * 0.8);
      } else {
        setNavBarTrans(false);
      }
    };

    window.addEventListener('scroll', handleScrollNew);

    return () => {
      window.removeEventListener('scroll', handleScrollNew);
    };
  }, [location]);
  return (
    <AppBar
      sx={{
        ...styleConst.textIndicatorColor,
        backgroundColor:
          navBarTrans && !isDrawerNeeded ? 'transparent' : gradientColor,
      }}
    >
      <ToolBar>
        <Grid container>
          <Grid item xs={2} justifyContent="flex-start">
            <Link to="/">
              <Logo1 />
            </Link>
          </Grid>
          {/* <Grid item xs={3} /> */}
          {isDrawerNeeded ? (
            <>
              <DrawerMenu
                key={value}
                menuList={menuList}
                handleScroll={handleScroll}
              />
            </>
          ) : (
            <Grid item xs={5} sx={{ marginLeft: 'auto' }}>
              <Tabs
                indicatorColor="secondary"
                textColor="inherit"
                value={value}
                onChange={handleChange}
              >
                {menuList.map((item, index) => (
                  // <Link to={menuListRef[index]}>
                  <Tab
                    key={index}
                    label={item}
                    onClick={() => handleScroll(index)}
                    component={Link} // Use Link as the component
                    to={index === 3 ? '/agentlogin' : '/'}
                  />
                  // </Link>
                ))}
              </Tabs>
            </Grid>
          )}
        </Grid>
      </ToolBar>
    </AppBar>
  );
};

export default NavBarTransparent;
