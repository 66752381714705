import {formDataTypeInterface} from '../../Components/Common/Constant/StaticData/CustomInterface';

export interface calculatorReducerInterface {
    isDataStored: boolean;
    calData:  formDataTypeInterface|null,
    roomsData: string|null,
    nights: string|null,
  }
  const stringToObject= (strData: string):formDataTypeInterface =>{
    const calDataObj =  JSON.parse(strData);
    return calDataObj;
  };
  const initialState: calculatorReducerInterface = {
    isDataStored: (String(localStorage.getItem('isDataStored')).toLowerCase() === 'true'),
    calData: stringToObject(localStorage.getItem('calData')!),
    roomsData: localStorage.getItem('roomsData'),
    nights: localStorage.getItem('nights')
  };
  const resetLocalStorage = () => {
    localStorage.removeItem('isDataStored')
    localStorage.removeItem('calData')
    localStorage.removeItem('roomsData')
    localStorage.removeItem('nights')
  }
  const calculatorReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'SET_CAL_DATA':
        localStorage.setItem('isDataStored', action.isDataStored.toString());
        localStorage.setItem('calData', JSON.stringify(action.calData));
        localStorage.setItem('roomsData', action.roomsData.toString());
        localStorage.setItem('nights', action.nights.toString());
        if (!action.isDataStored){
          resetLocalStorage();
          return {
            isDataStored: false,
            calData: null,
            roomsData: null,
            nights: null
          }
        }
        
        return {
          isDataStored: action.isDataStored,
          calData: action.calData,
          roomsData: action.roomsData,
          nights: action.nights
        };
      case 'RESET_CAL_DATA':
        resetLocalStorage();
        return {
            isDataStored: false,
          calData: null,
          roomsData: null,
          nights: null
        };
      default:
        return state;
    }
  };
  
  export default calculatorReducer;