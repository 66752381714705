import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Grid,
  Button,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { tripDataResponseInterface } from '../Common/Constant/StaticData/CustomInterface';
import { useSelector } from 'react-redux';
import { constColor } from '../Common/Constant/Constants';
import CustomImageDisplay from '../AdminImageView/CustomImageDisplay';
// import { Link } from 'react-router-dom'
type propsTypes = {
  tripId: string;
};
interface hoverStateInerface {
  raised: boolean;
  shadow: number;
}

const constStyle = {
  root: {
    maxWidth: 310,
    transition: 'transform 0.15s ease-in-out',
  },
  cardHovered: {
    transform: 'scale3d(1.05, 1.05, 1)',
  },
  cardStyle: {
    maxWidth: '100%',
    backgroundColor: 'teal',
    color: 'white',
    borderRadius: '0.8rem',
    boxShadow: '5px 5px 10px #000',
  },
};

const TripCard = ({ tripId }: propsTypes) => {
  const data: tripDataResponseInterface = useSelector(
    (state: any) => state.tripCardData.tripCardAPIData[tripId]
  );
  const [activeImage, setActiveImage] = useState<number>(0);
  const [hoverStarted, setHoverStarted] = useState<hoverStateInerface>({
    raised: false,
    shadow: 1,
  });
  const handlenextSlide = (fwd: boolean) => {
    if (fwd) {
      if (activeImage >= data.thumbnail.length - 1) setActiveImage(0);
      else setActiveImage(activeImage + 1);
    } else {
      if (activeImage === 0) setActiveImage(data.thumbnail.length - 1);
      else setActiveImage(activeImage - 1);
    }
  };
  useEffect(() => {
    if (hoverStarted.raised) {
      setTimeout(() => {
        handlenextSlide(true);
      }, 2 * 1000);
    }
  }, [hoverStarted, handlenextSlide]);

  // Customr Buttorn colour
  const theme = createTheme({
    palette: {
      primary: {
        main: '#999878',
      },
      secondary: {
        main: '#1D2951',
      },
    },
  });
  const handleMouseHover = (raised: boolean, shadow: number) => {
    setHoverStarted({ raised: raised, shadow: shadow });
  };
  // console.log(" active image: " + activeImage + "id: " + data.id);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Card
          sx={constStyle.cardStyle}
          onMouseOver={() => handleMouseHover(true, 3)}
          onMouseOut={() => handleMouseHover(false, 1)}
          raised={hoverStarted.raised}
        >
          <CardActionArea>
            <CustomImageDisplay
              imageId={data.image[activeImage]}
              height={230}
              width={700}
              isThumbnail={false}
            />
            {/* <CardMedia
              component="img"
              height="230"
              image={data.thumbnail[activeImage]}
            /> */}
          </CardActionArea>
          <CardContent>
            <Typography
              style={{ textAlign: 'center' }}
              gutterBottom
              variant="h5"
              component="div"
            >
              {data.location}
            </Typography>
            <Typography variant="body2" color={constColor.outlinedColor}>
              {data.shortDesc}
            </Typography>
            <hr style={{ color: constColor.horizontalBar }} />
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography
                  style={{ alignItems: 'right' }}
                  gutterBottom
                  component="div"
                >
                  Price Starts From ₹ {data.price}/-*
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button sx={{ color: constColor.outlinedColor }}>
                  Get Details
                </Button>

                {/* </Link> */}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </ThemeProvider>
    </>
  );
};

export default TripCard;
