import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import { Fade, IconButton, Slide, Snackbar } from '@mui/material';

type NotificationProps = {
  message: string;
  type: 'success' | 'warning' | 'error';
  open: boolean;
  onClose: () => void;
};

// Slide Transition component
const SlideTransition = (props: any) => {
  return <Slide {...props} direction="up" />;
};

// Fade Transition component
const FadeTransition = (props: any) => {
  return <Fade {...props} />;
};

const NotificationSnackBar: React.FC<NotificationProps> = ({
  message,
  type,
  open,
  onClose,
}) => {
  const getIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircleIcon />;
      case 'warning':
        return <WarningIcon />;
      case 'error':
        return <ErrorIcon />;
      default:
        return null;
    }
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      autoHideDuration={30000} // Close automatically after 30 seconds
      onClose={onClose}
      TransitionComponent={FadeTransition} // Use Fade transition
      message={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {getIcon()}
          {message}
        </div>
      }
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </>
      }
    />
  );
};

export default NotificationSnackBar;
