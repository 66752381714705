import { useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Carousel from './Carousel';
import MobileSlider from './MobileSlider';
import { sliderImageEmptyData } from '../Common/Constant/StaticData/APIEmptyData';
import { sliderImageInterface } from '../Common/Constant/StaticData/CustomInterface';
import { getAPIcall } from '../ApiBackend/ApiCal';

const Slider = () => {
  const [carouselData, setCarouselData] =
    useState<sliderImageInterface[]>(sliderImageEmptyData);
  const theme = useTheme();
  const isDrawerNeeded = useMediaQuery(theme.breakpoints.down('md'));
  const getSliderInfo = async () => {
    try {
      let sliderApiData = await getAPIcall('/api/v1/slider', {
        sort: 'sortOrder',
      });
      setCarouselData(sliderApiData);
      // console.log('image: ', sliderApiData);
    } catch (error) {
      console.log('eror: ', error);
    }
  };
  useEffect(() => {
    getSliderInfo();
  }, []);
  return (
    <div id="home">
      {isDrawerNeeded ? (
        <MobileSlider data={carouselData} />
      ) : (
        <Carousel data={carouselData} />
      )}
    </div>
  );
};

export default Slider;
