import { Container, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { constColor } from '../Common/Constant/Constants';
import CustomImageDisplay from '../AdminImageView/CustomImageDisplay';

type dataType = {
  image: string;
  caption: string;
};

type CarouselProps = {
  data: dataType[];
  autoplay?: boolean;
  animationDuration?: 1 | 2 | 3 | number;
};
const styleConst = {
  container: {
    // display: 'flex',
    backgroundColor: 'black',
    // height: '24rem',
    placeItems: 'center',
    gritTemplateAreas: 'inner-div',
    width: '100%',
    position: 'relative',
    marginTop: '4rem',
    paddingBottom: '1rem',
  },
  text: {
    color: constColor.textWhite,
  },
  image: {
    gridArea: 'inner-div',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative' as 'relative',
    transitionProperty: 'all',
    transitionDuration: '500ms',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    objectPosition: 'center',
  },
  rightArrow: {
    // marginLeft: '24rem',
    position: 'absolute' as 'absolute',
    top: '40%',
    right: '8px',
    // opacity: '0.5',
    fontSize: '3rem',
    transform: 'scale(0.9, 0.9)',
    zIndex: '10',
    color: constColor.textWhite,
  },
  leftArrow: {
    position: 'absolute' as 'absolute',
    top: '40%',
    left: '8px',
    // opacity: '0.5',
    fontSize: '3rem',
    transform: 'scale(0.9, 0.9)',
    zIndex: '10',
    color: constColor.textWhite,
  },
};
const MobileSlider = ({
  data,
  autoplay = true,
  animationDuration = 6,
}: CarouselProps) => {
  const [activeItem, setActiveItem] = useState<number>(0);

  useEffect(() => {
    autoplay &&
      setTimeout(() => {
        handlenextSlide(true);
      }, animationDuration * 1000);
  }, [activeItem]);

  const handlenextSlide = (fwd: boolean) => {
    if (fwd) {
      if (activeItem >= data.length - 1) setActiveItem(0);
      else setActiveItem(activeItem + 1);
    } else {
      if (activeItem === 0) setActiveItem(data.length - 1);
      else setActiveItem(activeItem - 1);
    }
  };

  return (
    <>
      <Container sx={styleConst.container}>
        <div style={styleConst.text}>
          <Typography variant="h6">{data[activeItem].caption}</Typography>
        </div>
        <div style={styleConst.image}>
          {/* <img
            src={data[activeItem].image}
            alt={data[activeItem].caption}
            width="100%"
            // styles={{}}
          /> */}

          <CustomImageDisplay
            imageId={data[activeItem].image}
            height={600}
            width={600}
            isThumbnail={false}
          />
          <div>
            <div
              style={styleConst.leftArrow}
              onClick={() => handlenextSlide(false)}
            >
              <ArrowBackIosIcon />
            </div>
            <div
              style={styleConst.rightArrow}
              onClick={() => handlenextSlide(true)}
            >
              <ArrowForwardIosIcon />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default MobileSlider;
