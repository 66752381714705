import { createAsyncThunk } from "@reduxjs/toolkit";
import { rootStoreType } from "../reducers";
import { getAllAPICall } from "../../Components/ApiBackend/AgentApiCal";


export const fetchAgentOrders = createAsyncThunk(
    'agentOrders/fetch',
    async ({},thunkAPI) => {
        const state: rootStoreType = thunkAPI.getState() as rootStoreType;
        const apiToken = state.auth.authToken;
        try{
            const existingData = state.agentOrdersData;
            if (Array.isArray(existingData) && existingData.length > 0){
                return existingData;
            } else{
                const getApiData = await getAllAPICall({
                    apiEndPoint: '/api/v1/agentorders',
            tokenId: apiToken!,
                })
                return getApiData;
            }
                
        }catch (error: any){
            return thunkAPI.rejectWithValue(error.message);
        }

    }
)