import React, { useState } from "react";
import { Box, Button, Grid, IconButton, Link, Typography } from "@mui/material";
import {
  TncText,
  PrivacyText,
  CancellationText,
  DisclaimerText,
} from "./StaticText";
import useRazorpay from "react-razorpay";
import {
  Business,
  Email,
  Facebook,
  Instagram,
  Phone,
  WhatsApp,
  YouTube,
} from "@mui/icons-material";
import {
  paymentRazorPayOrder,
  paymentRedirection,
} from "../../ApiBackend/ApiCal";
import PopupWrapper from "../Wrappers/PopupWrapper";
import { RenderDynamicField } from "../../AdminViewAddData/RenderType";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { razorPayKeyId } from "../Constant/Constants";
import WaveLoading from "../Animation/Loading/WaveLoading";
import { useNavigate } from "react-router-dom";
const styleConst = {
  root: {
    background: "#253f4d",
    display: "flex",
    padding: "2vw 5vw 2vw 5vw",
    color: "#fff",
    borderRadius: "1rem 0 0 0",
  },
  left: {
    width: "42vw",
    flexGrow: 1,
  },
  right: {
    width: "42vw",
    backgroundColor: "",
  },
  title: {
    fontSize: "2vw",
  },
  linkIcon: {
    color: "#fff",
  },
  linkText: {
    color: "#fff",

    "&:hover": {
      color: "#000",
    },
  },
  underlineOnHover: {
    marginBottom: "0.5rem",
    display: "block",
    color: "white",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  popupBase: {
    // top: '20%',
    overflow: "scroll",
    maxHeight: "30rem",
    maxWidth: "80%",
    width: "60%",
  },
  boxIcon: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
};

type dataNameType = {
  name: string;
  mobile: string;
  email: string;
  amount: number;
};
type razorPayResType = {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
};
declare global {
  interface Window {
    Razorpay: any;
  }
}
const Contact = () => {
  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();

  const [popupVisible, setPopupVisible] = useState(false);
  const [typeOfPopup, setTypeOfPopup] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const handlePopupClick = (type: number, val: boolean) => {
    setTypeOfPopup(type);
    setPopupVisible(val);
  };
  // Payment Popup
  const [popupButtonClicked, setPopupButtonClicked] = useState(false);
  const [customPaymentData, setCustomPaymentData] = useState<dataNameType>({
    name: "",
    mobile: "",
    email: "",
    amount: 0,
  });
  const [paymentMode, setPaymentMode] = useState("");

  // handle button click
  const onClickPayNow = (type: string) => {
    setPaymentMode(type);
    setPopupButtonClicked(true);
  };
  // submit handler for Phone pe
  const handledSubmit = async (event: React.FormEvent) => {
    const pgRes = await paymentRedirection(customPaymentData);
    console.log("payment response: ", pgRes);
    const redirectUrl = pgRes.redirectInfo.url;
    window.open(redirectUrl);
  };
  // Handle Submit for Razor Pay
  const handledSubmitRazor = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setPopupButtonClicked(false);
    // order created through razor pay orders api
    try {
      const createOrder = await paymentRazorPayOrder(customPaymentData);
      console.log("create order:, ", createOrder);

      const options = {
        // ...your Razorpay options (same as before)
        key: razorPayKeyId,
        amount: (customPaymentData.amount * 100).toString(),
        currency: "INR",
        name: "TripYog",
        description: "Custom Payment",
        image: "https://ik.imagekit.io/luhar/personal/logo-3.png",
        order_id: createOrder.id,
        handler: function (response: razorPayResType) {
          setIsLoading(false);
          navigate(
            `/payment/status?status=success&orderId=${createOrder.id}&paymentId=${response.razorpay_payment_id}&signature=${response.razorpay_signature}`
          );
        },
        prefill: {
          name: customPaymentData.name,
          email: customPaymentData.email,
          contact: customPaymentData.mobile,
        },
        notes: {
          address: "UI Custom Payment",
        },
        theme: {
          color: "#f57c00",
        },
      };
      console.log("options: , ", options);

      const razorpay = new Razorpay(options);
      razorpay.on("payment.failed", function (response: any) {
        setIsLoading(false);
        navigate(
          `/payment/status?status=failed&orderId=${response.error.metadata.order_id}&paymentId=${response.error.metadata.paymentId}}`
        );
      });
      razorpay.open();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      navigate(`/payment/status?status=failed`);
    }
  };
  // Return correct type
  const returnPopupType = () => {
    switch (typeOfPopup) {
      case 1:
        return <TncText />;
      case 2:
        return <PrivacyText />;
      case 3:
        return <CancellationText />;
      case 4:
        return <DisclaimerText />;
      default:
        break;
    }
  };
  return (
    <>
      {isLoading ? (
        <WaveLoading />
      ) : (
        <>
          <Box
            sx={{ backgroundColor: "#253f4d", color: "white", padding: "2rem" }}
          >
            <Grid container spacing={4} justifyContent="space-between">
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6" gutterBottom>
                  Contact Information
                </Typography>
                <Box sx={styleConst.boxIcon}>
                  <IconButton color="inherit" href="mailto:info@tripyog.in">
                    <Email />
                  </IconButton>
                  <Typography variant="body2">info@tripyog.in</Typography>
                </Box>
                <Box sx={styleConst.boxIcon}>
                  <IconButton color="inherit" href="tel:+917532812944">
                    <Phone />
                  </IconButton>
                  <Typography variant="body2">+91-7532812944</Typography>
                </Box>
                <Box sx={styleConst.boxIcon}>
                  <IconButton color="inherit" href="https://wa.me/917532812944">
                    <WhatsApp />
                  </IconButton>
                  <Typography variant="body2">+91-7532812944</Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6" gutterBottom>
                  Connect with Us
                </Typography>
                <Box>
                  <IconButton
                    color="inherit"
                    href="https://www.instagram.com/tripyog"
                  >
                    <Instagram />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    href="https://www.facebook.com/tripyog"
                  >
                    <Facebook />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    href="https://www.youtube.com/@tripyog"
                  >
                    <YouTube />
                  </IconButton>
                </Box>
                <Box sx={{ marginTop: "1rem" }}>
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    sx={{ color: "#fff" }}
                    endIcon={<CurrencyRupeeIcon />}
                    onClick={() => onClickPayNow("Phonepe")}
                  >
                    Pay with PhonePe
                  </Button>
                </Box>
                <Box sx={{ marginTop: "1rem" }}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    sx={{ color: "#fff" }}
                    endIcon={<CurrencyRupeeIcon />}
                    onClick={() => onClickPayNow("Razorpay")}
                  >
                    Pay with RazorPay
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6" gutterBottom>
                  Legal
                </Typography>
                <Link
                  href="#"
                  onClick={() => handlePopupClick(1, true)}
                  underline="hover"
                  sx={styleConst.underlineOnHover}
                >
                  Terms & Conditions
                </Link>
                <Link
                  href="#"
                  onClick={() => handlePopupClick(2, true)}
                  underline="hover"
                  sx={styleConst.underlineOnHover}
                >
                  Privacy Policy
                </Link>
                <Link
                  href="#"
                  onClick={() => handlePopupClick(3, true)}
                  underline="hover"
                  sx={styleConst.underlineOnHover}
                >
                  Cancellation Policy
                </Link>
                <Link
                  href="#"
                  onClick={() => handlePopupClick(4, true)}
                  underline="hover"
                  sx={styleConst.underlineOnHover}
                >
                  Disclaimer
                </Link>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6" gutterBottom>
                  Our Offices
                </Typography>
                <Box>
                  <Business />
                  <Typography variant="body2">
                    46 D, DDA Flats Pandav Nagar, Near Mother Dairy, Shadipur
                    Depot, New Delhi 110008
                  </Typography>
                </Box>
                <Box>
                  <Business />
                  <Typography variant="body2">
                    Indralok Nagar, Chhoti Pahari, Agam Kuan, Patna, Bihar -
                    800007
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <PopupWrapper
              title=""
              isOpen={popupVisible}
              handleClose={() => setPopupVisible(false)}
            >
              {returnPopupType()}
            </PopupWrapper>
          </Box>
          <PopupWrapper
            title={`Custom Pay Through ${paymentMode}`}
            isOpen={popupButtonClicked}
            handleClose={() => setPopupButtonClicked(false)}
            onSubmit={(event) =>
              paymentMode === "phonepe"
                ? handledSubmit(event)
                : handledSubmitRazor(event)
            }
          >
            <Grid container spacing={3}>
              {Object.entries(customPaymentData).map(
                ([eachKey, eachVal], index) => (
                  <Grid key={`grid_${eachKey}`} item>
                    <Box key={`box_${eachKey}`} sx={{ padding: "0.3rem" }}>
                      <RenderDynamicField
                        key={`renderPay_${eachKey}`}
                        keyColl={eachKey}
                        valueColl={eachVal}
                        collectionName="empty_structure"
                        curDataTable={customPaymentData}
                        setCurDataTable={setCustomPaymentData}
                        viewIsEnable={false}
                      />
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </PopupWrapper>
        </>
      )}
    </>
  );
};

export default Contact;
