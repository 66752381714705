import { Box, Tabs, Tab, Paper, Container } from '@mui/material';
import React from 'react';
import TabInfo from './TabInfo';
import { tripDataResponseInterface } from '../Common/Constant/StaticData/CustomInterface';
import { constColor } from '../Common/Constant/Constants';

interface productDetailsProps {
  tripData: tripDataResponseInterface;
}

const paperStyle = {
  display: 'flex',
  maxHeight: '18rem',
  overflow: 'scroll',
  // scroll: 'auto'
};

const ProductDetails = ({ tripData }: productDetailsProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Box
        sx={{
          width: '96%',
          bgcolor: constColor.tabsColor,
          borderRadius: '5px',
          marginLeft: '.5rem',
        }}
      >
        <Tabs
          value={value}
          variant="scrollable"
          onChange={handleChange}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: constColor.outlinedColor,
            },
            '& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected': {
              color: constColor.outlinedColor,
            },
          }}
        >
          <Tab key={1} label="Overview" />
          <Tab key={2} label="Itinerary" />
          <Tab key={3} label="Included" />
          <Tab key={4} label="Excluded" />
          <Tab key={5} label="Things to Carry" />
          <Tab key={6} label="Cancelation Policy" />
        </Tabs>
      </Box>
      <br />
      <Paper
        elevation={6}
        sx={{
          width: '96%',
          borderRadius: '5px',
          marginLeft: '0.35rem',
        }}
      >
        <Container style={paperStyle}>
          <TabInfo key={tripData._id} type={value} tripData={tripData} />
        </Container>
      </Paper>
    </>
  );
};
export default ProductDetails;
