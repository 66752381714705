import React from 'react';
import { AppBar, Container, Typography, Toolbar } from '@mui/material';

const styleConst = {
  toolbar: {
    textAlign: 'center',
  },
  container: {
    backgroundImage:
      'linear-gradient(90deg,rgba(0,212,255,1)0%, rgba(9,82,121,1) 48%,  rgba(2,0,36,1)  100%)',
    bottom: 0,
    width: '100%',
    // position: 'absolute',
  },
};
interface propsGet {
  capName: string;
}
function Footer({ capName }: propsGet) {
  const d = new Date();
  const yearCopy = d.getFullYear();

  return (
    <>
      <AppBar sx={styleConst.container} position="static">
        <Container maxWidth="md">
          <Toolbar style={{ textAlign: 'center' }}>
            <Typography variant="body1">
              Copyright © {yearCopy} {capName}. All right reserved
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Footer;
