
export interface AuthStateInterface {
    isLoggedIn: boolean;
    authToken: string |null,
    name: string |null,
    role: string | null
  }
  const initialState: AuthStateInterface = {
    isLoggedIn: false,
    authToken: localStorage.getItem('authagtoken'),
    name: localStorage.getItem('agname'),
    role: localStorage.getItem('agrole'),
  };
  
  const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'LOGIN':
        localStorage.setItem('authagtoken', action.payload.authToken);
        localStorage.setItem('agname', action.payload.name);
        localStorage.setItem('agrole', action.payload.role);
        return {
          isLoggedIn: true,
          authToken: action.payload.authToken,
          name: action.payload.name,
          role: action.payload.role
        };
      case 'LOGOUT':
        localStorage.removeItem('authagtoken');
        localStorage.removeItem('agname');
        localStorage.removeItem('agrole');
        return {
          isLoggedIn: false,
          authToken: null,
          name: null,
          role: null
        };
      default:
        return state;
    }
  };
  
  export default authReducer;
  
  